// urls.js

import React from 'react';

const urls = [
    "https://loskubalos.eu.org/orbion.html",
    "https://loskubalos.eu.org/index.html",
    "https://loskubalos.eu.org/images.html",
    "https://loskubalos.eu.org/gallery.html",
    "https://stibot.top/",
    "https://stibot.top/panel",
    "https://stibot.top/privacy-policy",
    "https://stibot.top/TermsofService",
    "https://patomusicians.onway.space/index.html",
    "https://onway.space/index.html",
    "https://onway.space/list.html"
];

function URLList() {
    return (
        <div>
            {urls.map((url, index) => (
                <a key={index} href={url}>{url}</a>
            ))}
        </div>
    );
}

export default URLList;
