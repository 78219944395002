// Panel.js

import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCppg2zLg81jskdgyKra9PSE1qJfHXWZ9M",
  authDomain: "loskubalos.firebaseapp.com",
  databaseURL: "https://loskubalos-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "loskubalos",
  storageBucket: "loskubalos.appspot.com",
  messagingSenderId: "475179318350",
  appId: "1:475179318350:web:fc2a1c9f7e0f2766e8d71f"
};

initializeApp(firebaseConfig);

const clientId = '1052842537074823188';
const clientSecret = 'EK8098MwwGf94f6Sljcwj493WOGhd-qL';
const redirectUri = 'https://stibot.top/panel'; //      https://stibot.onway.space/panel || http://localhost:3000/panel || https://stibot.top/panel

function Panel() {
  const [discordInfo, setDiscordInfo] = useState(null);
  const [sharedGuilds, setSharedGuilds] = useState(null);
  const [selectedGuildData, setSelectedGuildData] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);

  const [selectedGuildId, setSelectedGuildId] = useState(null);
  const [selectedTableUuid, setSelectedTableUuid] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      fetchDiscordInfo(code);
    }
  }, []);

  const authorizeDiscord = () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=identify%20guilds`;
  };

  const fetchDiscordInfo = async (code) => {
    const tokenResponse = await fetch('https://discord.com/api/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `client_id=${clientId}&client_secret=${encodeURIComponent(clientSecret)}&grant_type=authorization_code&code=${code}&redirect_uri=${encodeURIComponent(redirectUri)}`
    });
    const data = await tokenResponse.json();
    if (data.access_token) {
      const userResponse = await fetch('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `${data.token_type} ${data.access_token}`
        }
      });
      const userData = await userResponse.json();
      setDiscordInfo(userData);

      if (userData.id === '810190965737259058') {
        // Fetch bot's guilds directly if user ID matches
        const botToken = 'MTA1Mjg0MjUzNzA3NDgyMzE4OA.GEOq4T.rZX-bG5VqDfLn10wM0Rx6K7WPwaVf29fR3FZ1k';
        const botGuildsResponse = await fetch('https://discord.com/api/users/@me/guilds', {
          headers: {
            Authorization: `Bot ${botToken}`
          }
        });
        const botGuilds = await botGuildsResponse.json();
        setSharedGuilds(botGuilds);
      } else {
        const userGuildsResponse = await fetch('https://discord.com/api/users/@me/guilds', {
          headers: {
            Authorization: `${data.token_type} ${data.access_token}`
          }
        });
        const userGuilds = await userGuildsResponse.json();

        const botToken = 'MTA1Mjg0MjUzNzA3NDgyMzE4OA.GEOq4T.rZX-bG5VqDfLn10wM0Rx6K7WPwaVf29fR3FZ1k';
        const botGuildsResponse = await fetch('https://discord.com/api/users/@me/guilds', {
          headers: {
            Authorization: `Bot ${botToken}`
          }
        });
        const botGuilds = await botGuildsResponse.json();

        const sharedGuildsData = userGuilds.filter(userGuild => {
          const botGuild = botGuilds.find(g => g.id === userGuild.id);
          return botGuild && userGuild.permissions === 2147483647;
        });

        setSharedGuilds(sharedGuildsData);
      }
    }
  };

  const handleGuildClick = async (guild) => {
    const db = getDatabase();
    const guildRef = ref(db, `Guilds/${guild.id}`);
    const snapshot = await get(guildRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const tables = Object.keys(data).filter(key => data[key].settings && data[key].settings.table).map(key => ({
        name: data[key].settings.table,
        uuid: key
      }));
      setSelectedGuildData({ guildName: guild.name, tables, rawData: data });
      setSelectedTable(null); // Reset selected table data
    } else {
      console.log("No data available for this guild.");
      setSelectedGuildData(null);
      setSelectedTable(null);
    }
    setSelectedGuildId(guild.id);
    setSelectedTableUuid(null);
  };

  const handleTableClick = (tableUuid) => {
    const tasks = selectedGuildData.rawData[tableUuid];
    const taskEntries = Object.entries(tasks).filter(([key, value]) => key !== "settings");
    setSelectedTable({ tableName: tasks.settings.table, tasks: taskEntries });
    setSelectedTableUuid(tableUuid);
  };

  return (
    <div className="flex full">
      {discordInfo ? (
        <React.Fragment>
          <aside className="bar flex column">
            <div className="flex">
              <img className="avatar" src={`https://cdn.discordapp.com/avatars/${discordInfo.id}/${discordInfo.avatar}.png`} alt="Avatar" />
              <p className="title">{discordInfo.username}</p>
            </div>
            <p>GUILDS</p>
            <div className="flex column">
              {sharedGuilds && sharedGuilds.map(guild => (
                <button key={guild.id}
                  onClick={() => handleGuildClick(guild)}
                  className={`guild-button flex ${selectedGuildId === guild.id ? 'active' : ''}`}>
                  <img className="guildIcon" src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`} alt="Guild Avatar" />
                  <span>{guild.name}</span>
                </button>
              ))}
            </div>
          </aside>
          <div className="main flex column">
            {selectedGuildData ? (
              <React.Fragment>
                <div className="mainT">
                  <h3>Tables in {selectedGuildData.guildName}:</h3>
                  <div className="tables flex">
                    {selectedGuildData.tables.map((table, index) => (
                      <button key={index}
                        onClick={() => handleTableClick(table.uuid)}
                        className={`guild-button flex ${selectedTableUuid === table.uuid ? 'active' : ''}`}>
                        {table.name}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="tasks flex">
                  {selectedTable ? (
                    <React.Fragment>
                      <ul>
                        {selectedTable.tasks.map(([key, task], index) => (
                          <li key={index}>
                            <strong>{task.uuid}.</strong> {task.task}: {task.description}
                          </li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ) : (
                    <p>Please choose a table to view tasks.</p>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <p>Select a server to view tables and tasks.</p>
            )}
          </div>
        </React.Fragment>
      ) : (
        <button className="login-btn" onClick={authorizeDiscord}>Login with Discord</button>
      )}
      <div className="unsupported-message">
        <h2>Phone is not supported.</h2>
      </div>
    </div>
  );
}

export default Panel;