// Policy.js

import React from 'react';

function TermsofService() {
    return (
        <div>
            <header className="flex hero">
                <h1 className="textAlign">Stibot<br /><span className="frame">Terms of Service</span></h1>
                <div className="ic-mouse-scroll"></div>
            </header>

            <hr style={{ marginTop: '15vh', marginBottom: '5vh' }} />

            <div className="flex">
                <div className="flex nav">
                    <a className="home flex" href="/">Home</a>
                    <a href="/privacy-policy">Privacy policy</a>
                    <a href="/TermsofService">Terms of Service</a>
                    <a href="https://discord.com/api/oauth2/authorize?client_id=1052842537074823188&permissions=139653901393&scope=bot">Invite</a>
                    <a href="https://discord.gg/t8TCT8xeCS">Support</a>
                    <a className="login flex" href="/panel">Login</a>
                </div>
            </div>

            <hr style={{ marginTop: '15vh', marginBottom: '5vh' }} />

            <div className="flex textBox terms">
                <p>1. Acceptance of Terms<br/>
                    By using Stibot, you agree to these Terms of Service. If you do not agree, please do not use the bot.<br/><br/>
                    2. Use of the Bot<br/>
                    You may use Stibot to manage tasks and projects within Discord and preview your tasks on website. Misuse of the bot, including but not limited to spamming, harassment, or illegal activities, is prohibited.<br/><br/>
                    3. Data Collection<br/>
                    Stibot collects and stores data related to your tasks and projects. We do not share your data with third parties.<br/><br/>
                    4. Disclaimer<br/>
                    Stibot is provided "as is" without warranties of any kind. We are not liable for any loss or damage arising from your use of the bot.<br/><br/>
                    5. Changes to Terms<br/>
                    We may update these Terms of Service at any time. Continued use of Stibot constitutes acceptance of the updated terms.<br/><br/>
                    6. Contact
                    For any questions or concerns, please contact <a className="link" href="https://discord.gg/t8TCT8xeCS">discord.gg/t8TCT8xeCS</a>.</p>
            </div>

            <hr style={{ marginTop: '10vh', marginBottom: '10vh' }} />

            <footer className="flex column">
                <h1>Let's turn<br /><span className="text-gradient">dreams</span> into <span className="text-gradient">reality!</span></h1>
                <hr style={{ marginTop: '5vh', marginBottom: '5vh', borderColor: '#454545' }} />
                <a style={{ fontSize: '1rem', color: 'var(--lighter)', marginBottom: '5vh' }} href="https://loskubalos.eu.org">© Loskubalos 2024</a>
            </footer>
        </div>
    );
}

export default TermsofService;
