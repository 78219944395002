// App.js

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./home";
import Panel from "./panel";
import Policy from "./policy";
import Terms from "./terms";
import Urls from "./urls";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/panel" element={<Panel />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/TermsofService" element={<Terms />} />
        <Route path="/urls" element={<Urls />} />
      </Routes>
    </Router>
  );
}

export default App;