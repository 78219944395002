// Policy.js

import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <header className="flex hero">
        <h1 className="textAlign">Stibot<br /><span className="frame">Privacy policy</span></h1>
        <div className="ic-mouse-scroll"></div>
      </header>

      <hr style={{ marginTop: '15vh', marginBottom: '5vh' }} />

      <div className="flex">
        <div className="flex nav">
          <a className="home flex" href="/">Home</a>
          <a href="/privacy-policy">Privacy policy</a>
          <a href="/TermsofService">Terms of Service</a>
          <a href="https://discord.com/api/oauth2/authorize?client_id=1052842537074823188&permissions=139653901393&scope=bot">Invite</a>
          <a href="https://discord.gg/t8TCT8xeCS">Support</a>
          <a className="login flex" href="/panel">Login</a>
        </div>
      </div>

      <hr style={{ marginTop: '15vh', marginBottom: '5vh' }} />

      <div className="flex textBox textAlign">
        <p>Thank you for using our Discord bot. This privacy policy applies to our website <a className="link" href="/">StiBot.top</a> and our bot itself, and we take your privacy very seriously. We want to be transparent about the data we collect and how we use it.<br /><br />When you use our bot, we collect your guild ID and user ID so that we can save your tasks and display them on the website. We only collect this data when you use the bot, and we never share it with third parties. Your data is stored securely and is only accessible to you through the bot or website.<br /><br />We use Discord OAuth2 to authorize your Discord account on the site so that you can conveniently view your tasks.<br /><br />We will never use your data for purposes other than to provide the service you have requested.<br /><br />If you have any questions or concerns regarding our privacy policy, please do not hesitate to contact us at <a className="link" href="https://discord.gg/t8TCT8xeCS">discord.gg/t8TCT8xeCS</a>.</p>
      </div>

      <hr style={{ marginTop: '10vh', marginBottom: '10vh' }} />

      <footer className="flex column">
        <h1>Let's turn<br /><span className="text-gradient">dreams</span> into <span className="text-gradient">reality!</span></h1>
        <hr style={{ marginTop: '5vh', marginBottom: '5vh', borderColor: '#454545' }} />
        <a style={{ fontSize: '1rem', color: 'var(--lighter)', marginBottom: '5vh' }} href="https://loskubalos.eu.org">© Loskubalos 2024</a>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
